import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./app/store";
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9dcHNXRGFUU0RxXQ==');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function ErrorPage() {
    return (
        <>
            Error 404
            Please Contact Administrator
        </>
    );
}

const router = createBrowserRouter(
    [
        {
            path: '/',
            element: <App/>,
            errorElement: <ErrorPage/>,
            children: [
                // {
                //     path: "/",
                //     element: <Navigate to={pageData['Home'].pageUrl} replace={true}/>,
                // },
                // {
                //     path: pageData['Home'].pageUrl,
                //     element: <HomeComponent/>,
                // },
                // {
                //     path: pageData['customer-profile'].pageUrl,
                //     element: <CustomerProfile/>,
                // },
            ],
        },
    ],
    // {basename: process.env.REACT_APP_BASE_PATH}
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
