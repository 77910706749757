import {Container} from "@mui/material";
import {
    Agenda,
    Day, DragAndDrop,
    Month,
    MonthAgenda, PopupOpenEventArgs, Resize,
    ScheduleComponent, TimelineMonth, TimelineViews, TimelineYear, ViewDirective,
    ViewsDirective,
    Week,
    WorkWeek
} from "@syncfusion/ej2-react-schedule";
import {Inject} from "@syncfusion/ej2-react-navigations";
import * as React from "react";
import {useEffect, useRef} from "react";

export function CustomSchedule() {

    const startDate = new Date(2018, 0, 28, 10, 0);
    const scheduleRef = useRef<ScheduleComponent>(null);

    const data: object[] = [
        {
            EndTime: new Date(2024, 3, 30, 0, 0),
            Id: '2',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 29, 0, 0),
            Subject: 'Plumbing Checklist | Jaimungal | 3671 :: Pool',
        },
        {
            EndTime: new Date(2024, 3, 30, 0, 0),
            Id: '4',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 28, 0, 0),
            Subject: 'Underground Plumbing | Jaimungal | 3671 :: Pool',
        },
        {
            EndTime: new Date(2024, 3, 30, 12, 30),
            Id: '7',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 24, 0, 0),
            Subject: 'Steel/ Checklist | VP Highland Model | 3719 :: Pool',
        },
        {
            EndTime: new Date(2024, 3, 30, 0, 0),
            Id: '9',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 29, 0, 0),
            Subject: 'Tile Selections/ Pavers/ Finish | VP Highland Model | 3719 :: Pool',
        },
        {
            EndTime: new Date(2024, 3, 30, 0, 0),
            Id: '10',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 26, 0, 0),
            Subject: 'Layout/ Form Rebar Shell | VP Highland Model | 3719 :: Pool',
        },
        {
            EndTime: new Date(2024, 3, 30, 0, 0),
            Id: '10',
            IsAllDay: true,
            StartTime: new Date(2024, 3, 26, 0, 0),
            Subject: ' VP Highland Model | 3719 :: Pool',
        }, {

            Id: "11",
            Subject: 'Paris',
            StartTime: startDate,
            EndTime: new Date(2024, 3, 29, 3, 0),
            IsAllDay: false,
            RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;COUNT=5',
             RecurrenceException:"20240330T010000Z"
        },{

        Id: "12",
        Subject: 'Paris',
        StartTime: new Date(2018, 0, 28, 10, 0),
        EndTime: new Date(2018, 0, 28, 12, 30),
        IsAllDay: false,
        RecurrenceRule: 'FREQ=DAILY;INTERVAL=1;COUNT=8',
        RecurrenceException: '20180129T043000Z,20180131T043000Z,20180202T043000Z'
}
    ];

    const initialLoad = useRef<Boolean>(true);

    const eventSettings = {dataSource: data}

    const onPopupOpen = (args: PopupOpenEventArgs) => {
        if (args.type === 'Editor') {
            // Get the default editor template element
            const formElement = args.element.querySelector('.e-schedule-form');

            // Create new custom fields
            const customFieldRow = document.createElement('div');
            customFieldRow.className = 'custom-field-row';

            const customFieldLabel = document.createElement('label');
            customFieldLabel.innerHTML = 'custom-field';
            customFieldRow.appendChild(customFieldLabel);

            const customFieldInput = document.createElement('input');
            customFieldInput.className = 'e-field e-input';
            customFieldInput.name = 'custom-field';
            customFieldRow.appendChild(customFieldInput);

            // Append the custom fields to the form
            formElement?.appendChild(customFieldRow);
        }
    };


    useEffect(() => {
        if (initialLoad.current && scheduleRef.current) {
            const observer = new MutationObserver((mutationsList, observer) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const allDayAppointmentSection = scheduleRef.current?.element.querySelector('.e-all-day-appointment-section') as HTMLElement;
                        if (allDayAppointmentSection) {
                            console.log("try click");
                            allDayAppointmentSection.click();
                            observer.disconnect(); // Stop observing once the element is found and clicked
                            initialLoad.current = false;
                            break;
                        }
                    }
                }
            });

            observer.observe(scheduleRef.current.element, {
                childList: true,
                subtree: true,
            });

            // Cleanup the observer on component unmount
            return () => observer.disconnect();
        }
    }, []);


    return <Container>
        <ScheduleComponent
            ref={scheduleRef}
            eventSettings={eventSettings}
            selectedDate={startDate}
            popupOpen={onPopupOpen}
        >
            <ViewsDirective>
                <ViewDirective option={"Day"} startHour="10:00" endHour="18:00"/>
                <ViewDirective option={"Month"}/>
                <ViewDirective option={"Week"}/>
                <ViewDirective option={"WorkWeek"}/>
                <ViewDirective option={"Agenda"}/>
                <ViewDirective option={"TimelineDay"}/>
                <ViewDirective option={"TimelineWeek"}/>
                <ViewDirective option={"TimelineMonth"}/>
                <ViewDirective option={"TimelineYear"}/>


            </ViewsDirective>
            <Inject services={[Day, Week, WorkWeek, Month, Agenda, MonthAgenda
                , TimelineViews
                , TimelineMonth, TimelineYear,
                DragAndDrop, Resize]}/>
        </ScheduleComponent>
    </Container>;
}