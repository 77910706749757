import './App.css';
import {SidebarComponent, SidebarType} from '@syncfusion/ej2-react-navigations';
import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import {CustomSchedule} from "./pages/custom-schedule/CustomSchedule";

function App() {
    const auth =true
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let sidebarObj: SidebarComponent;
    let type: SidebarType = "Push";
    let showBackdrop: boolean = true;

    function onCreate(): void {
        sidebarObj.element.style.visibility = '';
    }

    // Toggle(Open/Close) the Sidebar
    function toggleClick(): void {
        sidebarObj.toggle();
    }




    return (
        <div className="control-section">
            <div id="wrapper">
                {/* Initializing the Sidebar component */}
                <SidebarComponent
                    enableGestures={true}
                    id="default-sidebar" ref={Sidebar => sidebarObj = Sidebar as SidebarComponent}
                    style={{visibility: "hidden"}} created={onCreate} showBackdrop={showBackdrop}
                    type={type} width="280px">

                    <Box sx={{backgroundColor: ""}}>
                        <IconButton
                            onClick={() => toggleClick()}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                        >
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                </SidebarComponent>
                <div>
                    <Box sx={{flexGrow: 1}}>

                        <AppBar position="static">
                            <Toolbar>
                                <IconButton
                                    onClick={() => toggleClick()}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{mr: 2}}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Little Transport
                                </Typography>
                                {auth && (
                                    <div>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleMenu}
                                            color="inherit"
                                        >
                                            <AccountCircle/>
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            keepMounted
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                                            <MenuItem onClick={handleClose}>My account</MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </Toolbar>
                        </AppBar>
                        <CustomSchedule/>
                    </Box>

                </div>
            </div>
        </div>
    )
}

export default App;